import React from "react"
import {FormattedMessage, injectIntl, Link, useIntl} from "gatsby-plugin-react-intl"
import {graphql} from "gatsby"
import Parser from "html-react-parser"

import Layout from "../components/layout"
import "../components/styles/npm-site.scss"

const AboutPage = ({data}) => {
    const intl = useIntl()
    const lang = intl.locale
    var members = ""
    if (lang === "ru") {
        members = data.ru_members.edges;
    } else if (lang === "en") {
        members = data.en_members.edges;
    }
    return (
        <Layout>
            <h1><FormattedMessage id="about.title"/></h1>
            <p><FormattedMessage id="about.descr"/></p>

            <h3 id="publications"><FormattedMessage id="about.pubs.title"/></h3>
            <p style={{marginTop: `0px`, marginBottom: `0px`}}><FormattedMessage id="about.pubs.available1"/><Link
                to="/publications"><FormattedMessage id="about.pubs.available2"/></Link></p>

            <h3 id="contacts"><FormattedMessage id="about.contacts.title"/></h3>
            <p style={{marginTop: `0px`, marginBottom: `0px`}}><FormattedMessage id="about.contacts.mail"/><a
                href='mailto&#58;&#110;p&#109;&#64;m%&#54;&#57;%70&#116;&#46;ru'>npm&#64;mip&#116;&#46;ru</a></p>
            <p style={{marginTop: `0px`}}><FormattedMessage id="about.contacts.telegram"/><a
                href="https://t.me/mipt_npm">https://t.me/mipt_npm</a></p>

            {/* ------------------------------ */}

            {members.map(({node}) => {
                const name = node.frontmatter.title;
                const photo = node.frontmatter.photo;
                const page_link = node.frontmatter.page_link
                const path = "../../images/members/"
                return (
                    <div key={node.frontmatter.id} id={node.frontmatter.id}>
                        <hr/>
                        <div className="row">
                            {photo
                                ? <div className="col-lg-2"><img src={path + photo} alt=" "/></div>
                                : <div className="col-lg-2"/>
                            }
                            <div className="col-lg-10">
                                {page_link
                                    ? <h2><Link to={page_link}>{name}</Link></h2>
                                    : <h2>{name}</h2>
                                }

                                <div>{Parser(node.html)}</div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </Layout>
    )
}

export default injectIntl(AboutPage)

export const query = graphql`
    query {
        ru_members: allMarkdownRemark(filter: {frontmatter: {content_type: {eq: "member"}, published: {ne: false}, language: {eq: "ru"}}},
            sort: {fields: frontmatter___order, order: ASC}) {
            edges{
                node {
                    id
                    html
                    frontmatter {
                        id
                        title
                        photo
                        language
                        page_link
                    }
                }
            }
        }

        en_members: allMarkdownRemark(filter: {frontmatter: {content_type: {eq: "member"}, published: {ne: false}, language: {eq: "en"}}},
            sort: {fields: frontmatter___order, order: ASC}) {
            edges{
                node {
                    id
                    html
                    frontmatter {
                        id
                        title
                        photo
                        language
                        page_link
                    }
                }
            }
        }
    }`